<template>
  <book-previewer/>
</template>

<script>
import BookPreviewer from '@/components/list/BookPreviewer.vue';

export default {
  data() {
    return {

    };
  },
  components: {
    BookPreviewer,
  },
};
</script>
